/* .ais-Highlight-highlighted {
    background-color: yellow;
    
  }

  .ais-Highlight {
    display: -webkit-box !important;
  }

  .ais-Highlight span {
    display: inline;
  } */

  .filter-border{
    border-top: 4px solid #ff0!important;
  }

  .product-card:hover {
    border: 1px solid rgb(230, 230, 230);
  }

  .ais-ClearRefinements-button--disabled {
    color: rgb(207, 207, 207) !important;
  }
  
  .ais-SearchBox input {
    width: 100%;
    height: auto;
    border: 1px solid #1b5df3 !important;
    font-size: 12px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    padding: 5px 16px 5px 30px;
    line-height: 30px;
    margin-bottom: 20px;
    border-radius: 0.25rem;
  }

  .MuiSlider-valueLabelOpen{
    background-color: #1b5df3 !important;
    color: white !important;
  }

  .search-icon-alg .fa-magnifying-glass {
    position: absolute;
    left: -21px;
    top: -16px;
    outline: none;
    color: #1b5df3;
  }

  .ais-SearchBox input[type=search] {
    padding: 5px 35px 5px 35px !important;
  }
  
  .ais-VoiceSearch button {
    position: absolute;
    right: 0;
    top: 10px;
    outline: none;
  }
  .voiceText {
    position: absolute;
    right: 70px;
    top: 10px;
  }
  .algolia-link h6 a {
    color: #1b5df3;
    line-height: 1.5rem;
  }
  
  .ais-VoiceSearch .ais-VoiceSearch-button {
    border: none;
    width: 24px;
    height: 24px;
    /* padding: 4px; */
    border-radius: 50%;
    color: #1b5df3;
    background-color: transparent;
  }
  
  .ais-SearchBox .ais-SearchBox-submit {
    display: none;
  }
  
  .ais-SearchBox input:focus {
    outline: none;
  }
  
  .ais-SearchBox .ais-SearchBox-reset {
    display: none;
  }
  
  .ais-SearchBox input[type=search] {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: top;
    padding: 5px 16px 5px 24px;
  }
  
  /* Custom styles for the Algolia Pagination component */
  
  .ais-Pagination-list {
    margin-top: 30px;
    display: flex !important;
    justify-content: center !important;
    list-style-type: none !important;
  }

  .ais-Pagination{
    position: relative;
    margin: 60px auto;
  }
  
  .ais-Pagination-link {
    padding: 6px;
    border: 1px solid #c4c8d8;
    color: #1b5df3;
    text-decoration: none;
    margin: 0 2px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .ais-Pagination-link:hover {
    background-color: #f5f5f5;
  }
  
  .ais-Pagination-link--selected {
    background-color: #1b5df3;
    color: #fff;
  }
  
  .ais-Pagination-link--disabled {
    pointer-events: none;
    color: #ccc;
  }
  
  .ais-RefinementList ul {
    list-style-type: none !important;
    padding-left: 0px !important;
  }

  .ais-RefinementList{
    margin-top:10px;
  }

  .ais-RefinementList .ais-RefinementList-item {
    margin-top:10px;
  }
  
  .ais-RefinementList-label {
    font-size: 14px;
    margin-right: 3px;
    display: flex;
  }
  
  .ais-RefinementList-item {
    cursor: pointer;
    color: #000;
    padding: 0 5px;
  }
  
  
  .ais-RefinementList-list {
    font-weight: 400;
    line-height: 1.5;
  }
  
  .ais-ClearRefinements button {
    color: #1b5df3;
    border: none;
    font-size: 13px;
  }
  
  #algolia-input input {
    outline: none;
    padding: 5px;
    padding-left: 30px;
    width: 250px;
    border: 1px solid #000;
    border-radius: 6px;
  }
  
  #algolia-input i {
    position: absolute;
    top: 25px;
    left: 20px;
  }
  
  #search-results ul {
    max-width: 800px;
    padding: 20px;
    position: absolute;
    z-index: 999;
    margin-top: 10px;
  }
  
  .react-autosuggest__container {
    width: 100%;
    max-width: 800px;
    position: relative;
    padding: 8px;
  }
  
  .react-autosuggest__input {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 25px;
    padding: 8px 40px 8px 32px;
    font-size: 16px;
    outline: none;
    background-color: #EBECF0 !important;
  }
  
  /* .react-autosuggest__container .react-autosuggest__suggestion--first {
    background-color: #044974;
    padding: 5px 5px;
    color: white !important;
  } */
  
  .react-autosuggest__container .react-autosuggest__suggestion{
    padding: 5px 5px;
  }
  
  .react-autosuggest__container {
    max-height: 480px;
    overflow: scroll;
  }
  
  /* Hide scrollbar for Chrome, Safari, and Opera */
  .react-autosuggest__container::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for Firefox */
  .react-autosuggest__container {
    scrollbar-width: none;
  }
  
  
  
  .custom-modal .modal-body {
    height: 80vh;
  }
  
  .search-icon {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    color: #999;
  }
  
  .search-input::placeholder {
    color: #e6e6e6;
  }
  
  #search-results li h6 {
    font-size: 14px !important;
    padding-left: 10px;
    color: white !important;
    
  }
  
  #search-results ul li {
    background-color: #1b5df3;
    padding: 10px 10px 0px 10px;
    color: white !important;
  }
  
  .navigation-btn {
    font-size: 14px;
    color: #888888;
  }
  
  .esc-btn {
    padding: 5px;
    background-color: #e6e6e6;
    font-size: 12px;
    margin-right: 3px;
    color: #202020;
  }
  
  .navigation-main .fa-arrow-up {
    padding: 5px;
    background-color: #e6e6e6;
    font-size: 12px;
    margin-right: 3px;
    color: #888888;
    height: 22px;
  }
  
  .navigation-main .fa-arrow-down {
    padding: 5px;
    background-color: #e6e6e6;
    font-size: 12px;
    margin-right: 5px;
    color: #888888;
    height: 22px;
  }
  
  /* .custom-modal .modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around !important;
  } */
  
  .search-icon-alg {
    position: absolute;
    top:30px;
    left: 33px;
    z-index: 10;
    color: #a0a0a0;
  }
  
  .ais-HierarchicalMenu ul li a {
    cursor: pointer;
    color: #000 !important;
    font-size: 14px !important;
    display: flex !important;
  }
  
  .ais-RefinementList-labelText {
    padding-left: 20px;
  }
  
  .ais-RefinementList-count {
    margin-left: auto;
  }
  
  .rc-slider-handle {
    border: solid 2px #1b5df3 !important;
  }
  
  .rc-slider-track {
    background-color: #1b5df3 !important;
  }
  
  .btn {
    padding: 10px 15px;
    background: #1b5df3;
    border-radius: 6px;
    color: #fff;
  }
  
  .default-btn-two {
    padding: 10px 15px !important;
    font-size: 13px !important;
  }
  
  .fromPara {
    font-size: 13px !important;
    margin-top: -2px;
    padding-right: 5px;
    color: #ffffff;
  }
  
  .priceDesc {
    position: absolute;
    top: 0;
    background: #4e342e !important;
    padding: 5px;
    right: 15px;
  }
  
  .priceHeading {
    color: #ffffff;
    margin-bottom: 0;
  }
  
  .addToCart {
    margin-top: -25px;
    margin-right: 13px;
  }
  
  .requestSample {
    margin-top: -25px;
    margin-right: 13px;
  }
  
  .buyNow {
    margin-top: -25px;
  }
  
  /* .ais-Highlight-nonHighlighted {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  } */
  

  
  .ais-SortBy-select {
    border: 1px solid #dddddd;
    padding: 5px;
    outline: none;
    font-size: 12px;
    font-weight: 600;
  }
  
  .ais-HierarchicalMenu-list {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 10px;
  }
  
  .rc-slider-tooltip {
    background-color: #333;
    color: #fff;
    padding: 4px;
    border-radius: 4px;
    font-size: 12px;
  }
  
  .short-desc {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .case-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .ais-Highlight-highlighted {
    text-decoration:underline;
    background-color: none !important;
    color: #000 !important;
  }

  .ais-Hits-list li:first-child  .name-search-tag {
    color: white !important;
  }

  .ais-Hits-list li:hover  .name-search-tag {
    color: white !important;
  }

  .ais-Hits-list li:first-child .price-tag-search{
    color: white !important;
  }

  .ais-Hits-list li:hover .price-tag-search{
    color: white !important;
  }

  .custom-scrollbar {
    overflow: auto;
  }

  .voiceTextSiteSearch {
    position: absolute;
    right: 21px;
    top: 10px;
}

.aa-Item[aria-selected=true] .aa-ItemIcon{
  color: white !important;
}

  .custom-swal-dimensions {
    width: 250px;   
    height: auto; 
    padding: 5px !important;
    
  }

  .swal2-icon-show {
    color: white !important;;
  }

  .aa-Panel{
    z-index: 99999;
  }

  .ais-InfiniteHits-loadMore{
    border: 1px solid #ccc;
    font-weight: 600 !important;
    font-size: 12px !important;
    border-radius: 6px !important;
    display: block !important;
    text-align: center !important;
    margin: 1rem auto;
    margin-bottom: 0 !important;
    padding: .5rem 1rem !important;
  }
  .ais-InfiniteHits-loadMore:hover{
    background: #1b5df3;
    color: #fff;
  }

  .ais-Pagination-item--selected a {
    background-color: #1b5df3 !important;
    color: white !important;
  }