@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base{
  body {
      @apply dark:bg-gray-800 bg-white text-black dark:text-white
  }
  /* .dark {
    .dark\:ais-RefinementList-labelText {
      @apply text-black;
    }
  } */
}


html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

p {
  margin: 1rem auto;
}

b,strong {
  font-weight: 600;
}

a {
  color: inherit;
  text-decoration: none;
}
* {
  box-sizing: border-box;
}


.mt-65 {
  margin-top: 4rem;
}
.stripMarginTop, .homeBannerMarginTop{
  margin-top: 4rem;
}

.card iframe {
  height: 230px !important;
}


#medicalDeviceReports .swiper-button-prev, #medicalDeviceReports .swiper-button-next{
  top: 35% !important
}

.AccordionBox {
  background: url(https://assets.delveinsight.com/img/home/jpg/banner_583_X_318.jpg);
  background-size: cover!important;
  position: relative;
  z-index: 0;
}
.AccordionBox:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  background: rgba(0,0,0,.8);
  top: 0;
  width: 100%;
  z-index: -1;
  opacity: .5;
}
.AccordionBox .button {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding: 0.5em 0;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  border: none;
  background: 0 0;
  outline: 0;
  display: flex;
  align-items: center;
}
.AccordionBox .button[aria-expanded=true] .icon::after {
  width: 0;
}
.AccordionBox .icon {
  display: inline-block;
  position: relative;
  left: 0;
  width: 42px;
  height: 42px;
  background: #ffffff1a;
}
.AccordionBox .icon::before {
  display: block;
  position: absolute;
  content: "";
  top: 20px;
  left: 15px;
  width: 10px;
  height: 2px;
  background: currentColor;
  color: #fff;
}
.AccordionBox .icon::after {
  display: block;
  position: absolute;
  content: "";
  top: 16px;
  left: 19px;
  width: 2px;
  height: 10px;
  background: currentColor;
  color: #fff;
}

.counterBox {
  background: url(https://assets.delveinsight.com/img/home/jpg/banner_1800_X_1200.jpg);
  background-size: cover;
  position: relative;
  z-index: 0;
}
.counterBox:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  background: #1b5df3;
  top: 0;
  opacity: .8;
  width: 100%;
  z-index: -1;
}



/* Hide the scrollbar track */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  -webkit-overflow-scrolling: touch;
}

/* Define the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #1b5df3;
  border-radius: 8px;
}

/* Change thumb color on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #1b5df3;
}

/* #report-slider .mySwiper .swiper-wrapper {
  transform: translate3d(0px, 40px, 0) !important;
} */

/* Define the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* ::placeholder{
  color: #fff !important;
  opacity: 1;
} */
/* input[type=text],input[type=email],input[type=number],input[type=file],input[type=date]{
  color: #fff !important;
} */

.circular-image {
    width: 250px; /* Adjust the width as needed */
    height: 250px; /* Adjust the height as needed */
    background-color: #ffffff; /* Example background color */
    clip-path: circle(50% at center); /* Create a circular shape */
}
.white-paper-press {
  width: 280px;
  height: auto;
}

.overlay-case {
  margin-bottom: 0px !important;
}

#bannerConsultingList{
  background-image: url(https://assets.delveinsight.com/img/consulting/jpg/service/asset_prioritization_without_svg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
#bannerConsultingList{
  display: flex;
  height: 114vh;
  max-height: 115vh;
  position: relative;
}
#bannerConsultingList:before {
  background-image: url(https://www.delveinsight.com/img/consulting/svg/01-wave.svg);
  height: 140px;
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  background-size: cover;
  bottom: 0;
  left: 0;
}
#bannerConsultingList .bannerContent{
  margin-top: 220px;
}
#bannerConsultingList .bannerHeading h1 {
  font-size: 32px;
  margin: 0;
  color: #fff;
  line-height: 1.5;
  text-transform: uppercase;
}
#bannerConsultingList .bannerHeading .heading-text {
  font-size: 16px;
  color: #fff;
  margin: 30px auto;
  line-height: 24px;
}


.about_collaps .MuiCardHeader-title {
  font-size: 16px !important;
}
.relatedDesc{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
#bannerMarketResearch .bannerHeading h1{
    font-size: 40px;
}
#bannerMarketResearch .marketResearchImg {
    position: relative;
    color: transparent;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    width: 400px;
    height: 400px;
}


#allReportsTabs .tabs {
  display: flex;
  background: aliceblue;
  padding: 0px 15px 0px 15px;
}

#allReportsTabs .tab {
  padding: 20px;
  cursor: pointer;
  border-bottom: none;
  background-color: aliceblue;
}

#allReportsTabs .tab.active {
  background-color:  aliceblue;
  border-bottom: 3px solid #1b5df3;
}

#allReportsTabs .tab-content {
  padding: 20px 0;
}
.latestReportName{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.latestReportDesc{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.market-research-reports{
  width: 30% !important;
}

#WhyPharmdelve .rare-box {
  height: 195px;
  width: 100%;
  position: relative;
  background: #437eeb;
}
#WhyPharmdelve .imageAbout{
    top: -155px;
    margin-bottom: -145px;
    position: relative;
}


.tab-report-store { 
  background-color: #1b5df3;
  color: white !important;
  font-weight: 600;
}

.else-report-tab {
  color: #1b5df3;
  font-weight: 600;
}

.group:hover #dropdown-menu {
  display: block;
}

.delve-primary{
  color:#1b5df3 !important;
}
.delve-bg-primary{
  background-color:#1b5df3 !important;
}


/* timeline */

#line {
  width: 100%;
  max-width: 1065px;
  height: 4px;
  background-color: #1b5df3;
  margin: 0 auto;
  position: relative;
  top: 260px;
}

#line::after {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--main);
  position: absolute;
  right: -5px;
  top: -4px;
}

#line::before {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--main);;
  position: absolute;
  left: -5px;
  top: -4px; 
}

/* branches */

#line .branch {
  width: calc(1530px/10);
  height: 170px;
  border-left: 3px solid #1b5df3;
  position: relative;
  bottom: 210px;
  left: 40px;
  display: inline-block;
  transition: all 1.5s ease;
}

#line .branch::after {
  content: '';
  width: 15px;
  height: 2px;
  border-bottom: 3px solid #1b5df3;
  position: absolute;
  bottom: 0;
  left: -2px;
}

#line .branch::before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--main);
  position: absolute;
  top: -4px;
  left: -5.5px;
}

#line .mirror-down {
  transform: scaleY(-1);  
  top: 43px;
}

/* inner content of branches */

#line .mirror-down .content {
transform: scaleY(-1);
bottom: 0;
}

#line .content {
  position: absolute;
  left: 30px;
  top: 0px;
  width: 200px;
}

/* icons */

#line .branch img {
position: absolute;
top: -150px;
left: -60px;
transform: scale(0.6);
}

#line .mirror-down img {
transform: scale(0.6) scaleY(-1);
}

@media (max-width: 1435px) {
  #line {
    transform: scale(0.9);
  }
}

@media (max-width: 1256px) {
  #line {
    transform: scale(0.8);
  }
}

@media (min-width: 1256px) {
  .img-col {
    width: 50%;
  }
} 


@media (max-width: 652px) {
  .bannerContent {
      width: 100%;
  }
}


#circular-service {
  background-color: #fff;
}

#circular-service p {
  margin: 0
}

#circular-service .btn {
  border: 2px solid #fff;
  background: #000;
  color: #fff;
  padding: 15px
}

#circular-service .btn:after {
  font-family: Material Icons;
  content: "\E5CC";
  display: inline-block;
  vertical-align: middle;
  margin-left: .25em;
  margin-top: -.2em;
  will-change: transform;
  transition: transform .2s cubic-bezier(.645,.045,.355,1);
  transform: translateX(0)
}

#circular-service .btn:hover {
  position: relative;
  transition: all .3s ease
}

#circular-service .container {
  position: relative;
  left: 0;
  right: 0
}

@media screen and (max-width: 480px) {
  #circular-service .large-screen {
      display:none
  }
}

#circular-service button.accordion {
  background-color: #282828;
  color: #fff;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: 0;
  font-size: 15px;
  transition: .4s
}

#circular-service button.accordion.active,#circular-service button.accordion:hover {
  background-color: #ff0;
  color: #000
}

#circular-service button.accordion:after {
  content: "+";
  color: #777;
  font-weight: 700;
  float: right;
  margin-left: 5px
}

#circular-service button.accordion.active:after {
  content: "\2212";
  color: #fff
}

#circular-service div.panel {
  padding: 0 18px;
  background-color: #000;
  max-height: 0;
  overflow: hidden;
  transition: max-height .2s ease-out
}

#circular-service .sm-screen-services {
  display: none
}

@media screen and (max-width: 769px) {
  #circular-service .sm-screen-services {
      display:block!important
  }
}

#circular-service .circle-container {
  position: absolute;
  width: 27em;
  height: 27em;
  padding: 0;
  border-radius: 50%;
  list-style: none;
  box-sizing: content-box;
  margin: 0;
  border: 1px dashed #000000;
}

#circular-service .circle-container>* {
  display: block;
  position: absolute;
  top: 70%;
  left: 52%;
  margin: -2em
}

#circular-service .circle-container>:first-of-type {
  transform: rotate(-28deg) translate(13.5em) rotate(28deg)
}

#circular-service .circle-container>:nth-of-type(2) {
  transform: rotate(20deg) translate(9em) rotate(-20deg)
}

#circular-service .circle-container>:nth-of-type(3) {
  transform: rotate(91deg) translate(6.5em) rotate(-91deg)
}

#circular-service .circle-container>:nth-of-type(4) {
  transform: rotate(163deg) translate(10.5em) rotate(-162deg)
}

#circular-service .circle-container>:nth-of-type(5) {
  transform: rotate(202deg) translate(16em) rotate(-202deg);
}

#circular-service .circle-container>:nth-of-type(6) {
  transform: rotate(235deg) translate(18.5em) rotate(-236deg)
}

#circular-service .circle-container>:nth-of-type(7) {
  transform: rotate(267deg) translate(19.5em) rotate(-267deg)
}

#circular-service .circle-container>:nth-of-type(8) {
  transform: rotate(299deg) translate(17.5em) rotate(-299deg)
}

#circular-service .circle-container a {
  display: block;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #0270d7;
  background: #1e5df3;
  height: 100px;
  width: 100px;
  font-size: 250%;
  padding: 15px;
  text-align: center;
  color: #fff!important;
  line-height: .5em
}
#circular-service .circle-container button {
  display: block;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #0270d7;
  background: #1e5df3;
  height: 100px;
  width: 100px;
  font-size: 250%;
  padding: 15px;
  text-align: center;
  color: #fff!important;
  line-height: .5em
}

#circular-service .circle-container a span {
  line-height: .3em
}

#circular-service .circle-container .active {
  border: 5px solid #0270d7;
  border-radius: 50%
}

#circular-service .circle-container i {
  display: block;
  padding: 6px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 50%
}

#circular-service .circle-container:before {
  content: "Our Services";
  position: absolute;
  /* z-index: 100; */
  top: 50%;
  text-align: center;
  width: 100%;
  font-size: 1.5rem;
  left: 0;
  right: 0;
  line-height: 1;
  display: block;
  margin: -10px auto 0;
  color: #000000!important;
  font-weight: 500;
}

#circular-service .services-container li {
  display: none
}

#circular-service .services-container li.active {
  display: block
}

#circular-service .service-item {
  background: 0 0;
  padding: 0;
  margin-top: 110px;
  width: 100%;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 6px;
  line-break: auto
}

#circular-service .service-item .header-topic {
  background: 0 0;
  padding: 10px 20px;
  border-radius: 4px 4px 0 0;
  box-shadow: none
}

#circular-service .service-item .body {
  padding: 0 20px 20px;
  color: #fff;
  margin-top: 0
}

#circular-service .service-item .header-topic .icon {
  float: left;
  display: table
}

#circular-service .service-item .header-topic .icon i {
  width: 40px;
  height: 40px;
  background: 0 0;
  border-radius: 100%;
  position: relative;
  top: -7px;
  left: -10px;
  display: table-cell;
  color: #fff;
  font-size: 200%;
  vertical-align: bottom;
  text-align: center
}

#circular-service .service-item .header-topic h4 {
  margin: 0;
  color: #fff
}

#circular-service .service-item .footer {
  background: 0 0;
  padding: 10px 20px;
  border-radius: 0;
  border-top: none;
  box-shadow: none
}

@media screen and (max-width: 480px) {
  #circular-service .circle-container {
      transform:scale(.5)
  }
}

.report-oncology {
  background-color: #1b5df3;
  color: white !important;
}




/* Therapeutic */
#Therapeutic .iq-feture-bg {
  background-image: url('/pharm-landing/vendor/img/elements-img/app/feature.png');
  background-position: 0 0;
  background-repeat: no-repeat !important;
  padding-top: 200px;
}
#Therapeutic .pdelve-feature-circle {
  position: relative;
  display: inline-block;
  width: 550px;
  height: 550px;
}
#Therapeutic .pdelve-feature-circle .pdelve-img::before {
  content: "";
  border: 2px dotted #c4d6f9;
  -webkit-animation: anti-clockwise 25s linear 100000;
  animation: anti-clockwise 25s linear 100000;
  width: 500px;
  height: 500px;
  border-radius: 900px;
  display: inline-block;
}
#Therapeutic .pdelve-feature-circle .pdelve-img::after {
  content: "";
  border: 2px dotted #c4d6f9;
  -webkit-animation: anti-clockwise 25s linear 100000;
  animation: anti-clockwise 25s linear 100000;
  width: 800px;
  height: 800px;
  border-radius: 900px;
  display: inline-block;
  position: absolute;
  left: -150px;
  top: -150px;
}
/* .pdelve-feature-circle .pdelve-img::before {
  content: "";
  border: 2px dotted #c4d6f9;
  -webkit-animation: anti-clockwise 25s linear 100000;
  animation: anti-clockwise 25s linear 100000;
  width: 500px;
  height: 500px;
  border-radius: 900px;
  display: inline-block;
} */
#Therapeutic .pdelve-feature-circle .pdelve-img {
  width: 500px;
  height: 500px;
  position: relative;
  transition: all .4s ease;
  transform-origin: center center
}
#Therapeutic .pdelve-feature-circle .circle-bg {
  box-shadow: 0px 20px 40px 0px rgba(67, 126, 235, 0.3);
  width: 140px;
  text-align: center;
  position: relative;
  height: 140px;
  line-height: 140px;
  border-radius: 900px;
  background: #437eeb;
}
#Therapeutic .pdelve-feature-circle .dot-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
#Therapeutic .pdelve-feature-circle .effect-circle:before {
  background: url('https://assets.delveinsight.com/pharm-landing/vendor/img/elements-img/app/circle.png');
  width: 270px;
  height: 270px;
  display: inline-block;
  content: "";
}
#Therapeutic .pdelve-feature-circle .effect-circle {
  width: 270px;
  height: 270px;
  border-radius: 900px;
  border: 1px solid #c4d6f9;
  -webkit-animation: rotate-center 25s linear 100000;
  animation: rotate-center 25s linear 100000;
}
#Therapeutic .pdelve-feature-circle .main-circle {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  margin-right: -50%;
}
#Therapeutic .pdelve-feature-circle .pdelve-img ul li .feature-info {
  position: absolute;
  margin: 0 auto;
  text-align: center;
}
#Therapeutic .pdelve-feature-circle .list-inline .list-inline-item .feature-info h6 {
  font-size: .875rem;
}
#Therapeutic .pdelve-feature-circle .pdelve-img ul li .feature-info .feature-img {
  height: 65px;
  width: 65px;
  line-height: 65px;
  border-radius: 90px;
  background: #ffffff;
  text-align: center;
  margin: 0 auto 5px;
  box-shadow: 0px 8px 38px 0px rgba(0, 0, 0, 0.1);
}
#Therapeutic .pdelve-feature-circle .pdelve-img ul li:nth-child(1) .feature-info {
  top: -38px;
  left: -16px;
  right: 0;
}
#Therapeutic .pdelve-feature-circle .pdelve-img ul li:nth-child(2) .feature-info {
  top: 10%;
  left: 0%;
}
#Therapeutic .pdelve-feature-circle .pdelve-img ul li:nth-child(3) .feature-info {
  top: 10%;
  right: 5%;
}
#Therapeutic .pdelve-feature-circle .pdelve-img ul li:nth-child(4) .feature-info {
  top: 44%;
  right: -8%;
}
#Therapeutic .pdelve-feature-circle .pdelve-img ul li:nth-child(5) .feature-info {
  top: 44%;
  left: -12%;
}
#Therapeutic .pdelve-feature-circle .pdelve-img ul li:nth-child(6) .feature-info {
  top: 78%;
  left: 8%;
}
#Therapeutic .pdelve-feature-circle .pdelve-img ul li:nth-child(7) .feature-info {
  top: 73%;
  right: 0%;
}
#Therapeutic .pdelve-feature-circle .pdelve-img ul li:nth-child(8) .feature-info {
  bottom: -16%;
  left: 43%;
}
#Therapeutic .feature-img img {
  width: 40px;
  position: relative;
  left: 13px;
  top: 11px;
}
@keyframes circle {
  0% {
      transform: rotate(0deg)
  }
  15% {
      transform: rotate(2deg)
  }
  33% {
      transform: rotate(4deg)
  }
  66% {
      transform: rotate(2deg)
  }
  100% {
      transform: rotate(0deg)
  }
}



#PrCaseStudy .column {
  width: 100%;
  padding: 0 1em 1em 1em;
  text-align: center;
}
#PrCaseStudy .card {
  width: 100%;
  height: 100%;
  padding: 2em 1.5em;
  background: linear-gradient(#ffffff 50%, #1b5df3 50%);
  background-size: 100% 200%;
  background-position: 0 2.5%;
  border-radius: 12px;
  /* box-shadow: 0 0 35px rgba(0, 0, 0, 0.12); */
  cursor: pointer;
  transition: 0.5s;
}


#PrCaseStudy .icon-wrapper {
  background-color: transparent;
  position: relative;
  font-size: 30px;
  color: #1b5df3;
  place-items: center;
  transition: 0.5s;
}
#PrCaseStudy .card:hover {
  background-position: 0 100%;
  height: 100%;
}
#PrCaseStudy .card:hover .icon-wrapper {
  background-color: transparent;
  color: #ffffff;
}
#PrCaseStudy .card:hover .learn-more-hvr {
  display: block !important;
  color: #ffffff;
}
#PrCaseStudy .card:hover h3 {
  color: #ffffff;
}
#PrCaseStudy .card:hover p {
  color: #f0f0f0;
}
@media screen and (min-width: 768px) {
  #PrCaseStudy .column {
      flex: 0 50%;
      max-width: 50%;
  }
}
@media screen and (min-width: 992px) {
  #PrCaseStudy .column {
      flex: 0 0 33.33%;
      max-width: 33.33%;
  }
}

@media screen and (max-width: 1000px) {
  #clients-grid {
    flex: 0 0 33.33%;
        max-width: 33.33%;
        align-items: center;
  }
}

.our-services {
  overflow: hidden;
  position: relative;
  background: #f8f8f8;
}
.our-services.rotated-bg:before {
  content: '';
  position: absolute;
  width: 1000px;
  transform: translate(-50%, -50%) rotate(45deg);
  height: 886px;
  left: 10%;
  top: 50%;
  background: #1b5df3;
}
.our-services .sec-title {
  text-align: center;
}
.sec-title {
  position: relative;
  margin-bottom: 20px;
}
.sec-title h2 {
  position: relative;
  font-size: 40px;
  margin-bottom: 30px;
  text-transform: capitalize;
  font-weight: 700;
}
.center {
  text-align: center;
}
.our-services .sec-title p {
  max-width: 650px;
  margin: 0 auto;
  color: #848484;
  margin-top: 20px;
}
.sec-title h2.center:before {
  position: absolute;
  content: '';
  background: #26cdff;
  width: 50px;
  height: 3px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -15px;
}
.our-services .clearfix {
  margin-top: 40px;
}
.single-service-item {
  margin-bottom: 26px;
  position: relative;
  padding: 38px 20px 38px;
  background: #fff;
  text-align: center;
  box-shadow: 0 0 35px rgba(0,0,0,.1);
  -webkit-transition: .5s;
  transition: .5s;
  border: 2px solid #eee;
}
.single-service-item:hover {
  border-color: #1b5df3;
  -webkit-transition: .5s;
  transition: .5s;
}
.our-services .single-service-item .service-icon {
  overflow: hidden;
  text-align: center;
}
.our-services .single-service-item .service-icon img {
  max-width: 64px;
}
.service-text {
  overflow: hidden;
}
.service-text h4 {
  text-transform: capitalize;
  margin-bottom: 15px;
  margin-top: 10px;
}
.service-text p {
  color: #777777;
  line-height: 22px;
}
.service-text h4 a {
  color: #222222;
  transition: .5s;
}
.service-text h4 a:hover {
  color: #1b5df3;
  transition: .5s;
  text-decoration:none;
}


/* .our-services {
  overflow: hidden;
  position: relative;
  padding: 50px 0 70px;
  background: #f8f8f8;
}
.our-services.rotated-bg:before {
  content: '';
  position: absolute;
  width: 1000px;
  transform: translate(-50%, -50%) rotate(45deg);
  height: 886px;
  left: 10%;
  top: 50%;
  background: #1b5df3;
}
.our-services .sec-title {
  text-align: center;
}
.sec-title {
  position: relative;
  margin-bottom: 20px;
}
.sec-title h2 {
  position: relative;
  font-size: 40px;
  margin-bottom: 30px;
  text-transform: capitalize;
  font-weight: 700;
}
.center {
  text-align: center;
}
.our-services .sec-title p {
  max-width: 650px;
  margin: 0 auto;
  color: #848484;
  margin-top: 20px;
}
.sec-title h2.center:before {
  position: absolute;
  content: '';
  background: #26cdff;
  width: 50px;
  height: 3px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -15px;
}
.our-services .clearfix {
  margin-top: 40px;
}
.single-service-item {
  margin-bottom: 26px;
  position: relative;
  padding: 38px 20px 38px;
  background: #fff;
  text-align: center;
  box-shadow: 0 0 35px rgba(0,0,0,.1);
  -webkit-transition: .5s;
  transition: .5s;
  border: 2px solid #eee;
}
.single-service-item:hover {
  border-color: #1b5df3;
  -webkit-transition: .5s;
  transition: .5s;
}
.our-services .single-service-item .service-icon {
  overflow: hidden;
  text-align: center;
}
.our-services .single-service-item .service-icon img {
  max-width: 64px;
} */


#Usp .iq-feture-bg {
  background-image: url('/pharm-landing/vendor/img/elements-img/app/feature.png');
  background-position: 0 0;
  background-repeat: no-repeat !important;
  padding-top: 200px;
}
#Usp .pdelve-feature-circle {
  position: relative;
  display: inline-block;
  width: 550px;
  height: 550px;
}
#Usp .pdelve-feature-circle .pdelve-img::before {
  content: "";
  border: 2px dotted #c4d6f9;
  -webkit-animation: anti-clockwise 25s linear 100000;
  animation: anti-clockwise 25s linear 100000;
  width: 500px;
  height: 500px;
  border-radius: 900px;
  display: inline-block;
}
#Usp .pdelve-feature-circle .pdelve-img {
  width: 500px;
  height: 500px;
  position: relative;
  transition: all .4s ease;
  transform-origin: center center
}
#Usp .pdelve-feature-circle .circle-bg {
  box-shadow: 0px 20px 40px 0px rgba(67, 126, 235, 0.3);
  width: 140px;
  text-align: center;
  position: relative;
  height: 140px;
  line-height: 140px;
  border-radius: 900px;
  background: #437eeb;
}
#Usp .pdelve-feature-circle .dot-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
#Usp .pdelve-feature-circle .effect-circle:before {
  background: url('https://assets.delveinsight.com/pharm-landing/vendor/img/elements-img/app/circle.png');
  width: 270px;
  height: 270px;
  display: inline-block;
  content: "";
}
#Usp .pdelve-feature-circle .effect-circle {
  width: 270px;
  height: 270px;
  border-radius: 900px;
  border: 1px solid #c4d6f9;
  -webkit-animation: rotate-center 25s linear 100000;
  animation: rotate-center 25s linear 100000;
}
#Usp .pdelve-feature-circle .main-circle {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  margin-right: -50%;
}
#Usp .pdelve-feature-circle .pdelve-img ul li .feature-info {
  position: absolute;
  margin: 0 auto;
  text-align: center;
}
#Usp .pdelve-feature-circle .list-inline .list-inline-item .feature-info h6 {
  font-size: .875rem;
}
#Usp .pdelve-feature-circle .pdelve-img ul li .feature-info .feature-img {
  height: 65px;
  width: 65px;
  line-height: 65px;
  border-radius: 90px;
  background: #ffffff;
  text-align: center;
  margin: 0 auto 5px;
  box-shadow: 0px 8px 38px 0px rgba(0, 0, 0, 0.1);
}
#Usp .pdelve-feature-circle .pdelve-img ul li:nth-child(1) .feature-info {
  top: -38px;
  left: -16px;
  right: 0;
}
#Usp .pdelve-feature-circle .pdelve-img ul li:nth-child(2) .feature-info {
  top: 10%;
  left: 0%;
}
#Usp .pdelve-feature-circle .pdelve-img ul li:nth-child(3) .feature-info {
  top: 10%;
  right: 2%;
}
#Usp .pdelve-feature-circle .pdelve-img ul li:nth-child(4) .feature-info {
  top: 44%;
  right: -20%;
}
#Usp .pdelve-feature-circle .pdelve-img ul li:nth-child(5) .feature-info {
  top: 44%;
  left: -12%;
}
#Usp .pdelve-feature-circle .pdelve-img ul li:nth-child(6) .feature-info {
  top: 78%;
  left: 4%;
}
#Usp .pdelve-feature-circle .pdelve-img ul li:nth-child(7) .feature-info {
  top: 73%;
  right: 0%;
}
#Usp .pdelve-feature-circle .pdelve-img ul li:nth-child(8) .feature-info {
  bottom: -10%;
  left: 43%;
}
#Usp .feature-img img {
  width: 40px;
  margin: auto;
  padding-top: 15px;
}



/* Loader.css */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.hoverOnexpended:hover {
  -webkit-box-shadow: 0 10px 34px -8px rgb(156 156 156 / 75%);
  -moz-box-shadow: 0 10px 34px -8px rgb(156 156 156 / 75%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.button-container {
  position: relative;
}

.image-container {
  width: 5rem;
  height: 5rem;
  margin: auto;
  border: 2px solid #1b5df3; /* Set default border color */
  padding: 10px;
  position: relative;
  border-radius: 10px;
}

.active-image {
  background-color: #1b5df3; /* Set the background color for the active state */
  border-color: #ffffff; /* Set the border color for the active state */
}

.inactive-image {
  background-color: transparent; /* Set the background color for the inactive state */
}

.label-container {
  margin-top: 7px;
  text-align: center;
  font-size: 13px;
}
.caseUlDesign ul{
  list-style: disc;
  padding: 0 20px;
  margin: 0;

}


#articleContent a{
  color:#1b5df3 !important;
}
#articleContent p{
  font-size: 15px;
  margin: 6px 0;
}
/* #articleContent iframe {
  display: block;
  position: relative;
  padding: 1rem;
  background: #fff;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  width: 100%;
  height: 450px !important;
  margin: 2rem auto !important;
} */

#articleContent table{
  width: 100% !important;
  height: 100% !important;
}
#articleContent table td {
  padding: 0.5rem 2rem !important;
  border: 1px solid #ccc !important;
}
#articleContent table td p {
  margin: 0;
}
.reportThumb {
  width: calc(100px + 10px);
  -webkit-perspective: 400px;
  perspective: 400px
}
.mainTitleImgThumb {
  position: relative;
  display: block;
  width: 100%
}
.mainTitleImgThumb:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 0;
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  box-shadow: 5px -20px 40px 10px #454748;
}
.mainTitleImgThumb:hover {
  position: relative;
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotate3d(0,1,0,-20deg);
  transform: rotate3d(0,1,0,-20deg);
  transition: -webkit-transform .25s;
  transition: transform .25s;
  transition: transform .25s,-webkit-transform .25s
}
.mainTitleImgThumb .mainTitleImgHover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(255,255,255,.2);
  opacity: 0;
  transition: opacity .25s
}
.mainTitleImgThumb .mainTitleFrontImg {
  position: absolute;
  top: 2px;
  left: calc(72%);
  background: #f3f3f3;
  height: calc(100% - 2 * 2px);
  width: 40px;
  -webkit-transform: translate3d(0,0,-20px) rotate3d(0,1,0,90deg);
  transform: translate3d(0,0,-20px) rotate3d(0,1,0,90deg)
}
.mainTitleImgThumb .mainTitleBackImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 135px;
  -webkit-transform: translate3d(0,0,-40px);
  transform: translate3d(0,0,-40px);
  background-color: #a2a3a4;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%)
}
.reportBoxImgBook img {
  vertical-align: bottom
}
.mainTitleImgThumb .reportBoxMainImg {
  position: relative;
  width: 100px;
  height: 135px;
  background-color: #a2a3a4;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%)
}
/* #articleContent .faqsSection button{
  background: transparent;
  border: 0;
  border-bottom: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: none;
} */
/* #articleContent .faqsSection button h3{
  padding: 0 1rem;
  font-size: 1rem;
} */
#articleContent .faqsSection .border{
  /* border: 0; */
  box-shadow: none;
}


#book-card .card-container {
  display: flex;
  justify-content: space-around;
}

/* #book-card .card {
  perspective: 1000px;
} */

#book-card .card-inner {
  transform-style: preserve-3d;
}

#book-card .card:hover .card-inner {
  transform: rotateY(180deg);
}

#book-card .card:hover .card-face.card-back {
  transform: rotateX(180deg);
}

#book-card .card-face {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
  padding: 10px;
  transition: transform 0.5s;
}

#book-card .card-front {
  background-color: #3498db;
}

#book-card .card-back {
  background-color: #e74c3c;
  transform: rotateX(180deg);
}

#book-card .folded .card-inner {
  transform: rotateY(180deg);
}



.wave {
  -webkit-animation-name: ripple;
  animation-name: ripple;
  -webkit-animation-iteration-count: linear;
  animation-iteration-count: linear
}

.wave, .wave2 {
  position: absolute;
  opacity: 0;
  width: 6em;
  height: 6em;
  border: 1px solid #56a9e8;
  border-radius: 7em;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  text-align: center;
  top: 0
}

.wave2 {
  -webkit-animation-name: ripple2;
  animation-name: ripple2;
  -webkit-animation-iteration-count: linear;
  animation-iteration-count: linear
}

.wave3 {
  -webkit-animation-name: ripple3;
  animation-name: ripple3;
  -webkit-animation-iteration-count: linear;
  animation-iteration-count: linear
}

.wave3, .wave4 {
  position: absolute;
  opacity: 0;
  width: 6em;
  height: 6em;
  border: 1px solid #56a9e8;
  border-radius: 7em;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  text-align: center;
  top: 0
}

.wave4 {
  -webkit-animation-name: ripple4;
  animation-name: ripple4;
  -webkit-animation-iteration-count: linear;
  animation-iteration-count: linear
}

@-webkit-keyframes ripple {
  0% {
      opacity: .8
  }

  to {
      transform: scale(1.2);
      opacity: 0
  }
}

@keyframes ripple {
  0% {
      opacity: .8
  }

  to {
      transform: scale(1.2);
      opacity: 0
  }
}

@-webkit-keyframes ripple2 {
  0% {
      opacity: .7
  }

  to {
      transform: scale(1.5);
      opacity: 0
  }
}

@keyframes ripple2 {
  0% {
      opacity: .7
  }

  to {
      transform: scale(1.5);
      opacity: 0
  }
}

@-webkit-keyframes ripple3 {
  0% {
      opacity: .7
  }

  to {
      transform: scale(2);
      opacity: 0
  }
}

@keyframes ripple3 {
  0% {
      opacity: .7
  }

  to {
      transform: scale(2);
      opacity: 0
  }
}

@-webkit-keyframes ripple4 {
  0% {
      opacity: .6
  }

  to {
      transform: scale(2.5);
      opacity: 0
  }
}

@keyframes ripple4 {
  0% {
      opacity: .4
  }

  to {
      transform: scale(2.5);
      opacity: 0
  }
}



.infographicBox {
  position: relative;
}

.infographicBox .infographicItem {
  position: relative
}

.infographicItem {
  width: 100%;
  max-height: 700px;
  display: block;
  margin: 0 auto;
  overflow: hidden
}

.infographicItem:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: background-color .3s ease;
  transition: background-color .3s ease;
  background: -webkit-linear-gradient(0deg,rgb(0 0 0),rgb(0 0 0 / 62%) 60%,rgba(0,0,0,0));
  background: linear-gradient(0deg,rgb(0 0 0),rgb(0 0 0 / 62%) 60%,rgba(0,0,0,0));
  z-index: 1
}






.sampleReportBox:after,.sampleReportBox:before {
  content: "";
  position: absolute
}

.sampleReportBox {
  width: 100%;
  height: 40px;
  background: #f44336;
  position: absolute;
  top: 45%;
  left: 20px
}

.infoGraphicImg img {
  width: 45%;
  position: absolute;
  top: 4px;
  left: 8px;
  box-shadow: rgb(99 99 99 / 55%) 0 2px 8px 0
}

.sampleReportBox:after {
  height: 0;
  width: 0;
  border-bottom: 20px solid #ce372c;
  border-right: 21px solid transparent;
  right: 0;
  top: -20px
}

.sampleReportBox p {
  color: #fff;
  right: 10px;
  position: absolute;
}

.sampleReportBox .fa-download {
  color: #fff
}

@media screen and (min-width: 360px) and (max-width:991px) {
  .sampleReportItem a {
      width:94%
  }

  .infoGraphicImg img {
      width: 37%;
      position: absolute;
      top: 25px;
      left: 20px;
      box-shadow: rgb(99 99 99 / 55%) 0 2px 8px 0
  }

  .sampleReportBox p {
      right: 40px
  }
}



.licPriTypeBtn {
  background-image: linear-gradient(to right,#efe33f 0,#fffd7e 51%,#e1d200 100%);
  transition: .5s;
  background-size: 200% auto;
}

.licPriTypeBtn:hover {
  background-position: right center;
  color: #303030;
  text-decoration: none
}
.hightLightBtn {
  background-image: linear-gradient(to right,#1b5df3 0,#26a0da 51%,#1b5df3 100%);
  transition: .5s;
  background-size: 200% auto;
}

.hightLightBtn:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

.hightLightBtn1 {
  background-image: linear-gradient(to right,#d10000 0,#f51212 51%,#d10000 100%);
  transition: .5s;
  background-size: 200% auto;
}

.hightLightBtn1:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

.hightLightBtn2 {
  background-image: linear-gradient(to right,#000dff 0,#0091ff 51%,#3901e1 100%);
  transition: .5s;
  background-size: 200% auto;
}

.hightLightBtn2:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}
.transitionRotate{
  transition: all .3s ease;
  transform: perspective(50em) translateZ(0) translate(0,0) rotateY(0) rotateX(0);
}
.transitionRotate:hover{
  transform: perspective(50em) translateZ(0) translate(0,0) rotateY(30deg) rotateX(20deg);

}


.ais-Hits {
  margin-top: 1rem;
}
.aa-DetachedSearchButtonQuery {
  color : white !important;
}

/* .aa-Source {
  display: flex !important;
  justify-content: space-between;
} */
.aa-DetachedContainer--modal{
  width: 70% !important;
  max-width: 100% !important;
}
/* .aa-Source{
  background: #f5f5fa99;
  width: 100% !important;
} */
/* .aa-SourceFooter{
  width: 40%;
  border-left: 1px solid #eee;
  padding: 20px;
} */
.aa-PreviewImage{
  display: flex;
  justify-content: center;
}
.aa-PreviewImage img{
  width: 35%;
  height: 160px;
}
.aa-PreviewTitle{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.aa-PreviewDescription{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.aa-PreviewTitle{
  margin: 15px 0;
  font-weight: 600;
  line-height: 1.3;
  color: #1b5df3;
}
.aa-Item[aria-selected=true]{
  background-color: #1b5df3 !important;
  color: #fff;
}
/* .aa-List{
  width: 60% !important;
  max-height: 600px !important;
  overflow-y: scroll !important;
} */
.aa-Item{
  border-bottom: 1px solid #eee !important;
  border-radius: 0 !important;
  padding: 0 1rem !important;
  background: #fff;
}
.aa-Item[aria-selected=true] .aa-ItemActionButton svg{
  color: #fff;
}

.aa-List, .aa-SourceHeader{
  max-height: 350px !important;
}
.aa-DetachedContainer--modal .aa-PanelLayout{
  padding: 0 !important;
}
.navSerach .aa-DetachedSearchButtonPlaceholder{
  display: none;
}
.navSerach .aa-DetachedSearchButton{
  border: none;
  padding: 0;
}
.navSerach .aa-DetachedSearchButtonIcon{
  cursor: pointer !important;
}
.navSerach .aa-DetachedSearchButton:focus{
  border: none !important;
  box-shadow: none !important;
}
.navSerach .app-container{
  margin: 0 !important;
}

.aa-DetachedSearchButtonPlaceholder{
  color:#000 !important;
}
@media (max-width: 1140px) {
  .aa-SourceFooter{
    display: none;
  }
  .aa-List, .aa-SourceHeader{
    width: 100% !important;
  }
  .aa-List{
    padding-right: 0 !important;
  }
}

.rfpForm input::-webkit-input-placeholder, .rfpForm optgroup::-webkit-input-placeholder, .rfpForm select::-webkit-input-placeholder, .rfpForm textarea::-webkit-input-placeholder {
  color: #fff !important;
}

.rfpForm input:-moz-placeholder, .rfpForm optgroup:-moz-placeholder, .rfpForm select:-moz-placeholder, .rfpForm textarea:-moz-placeholder {
  color: #fff !important;
}

.rfpForm input::-moz-placeholder, .rfpForm optgroup::-moz-placeholder, .rfpForm select::-moz-placeholder, .rfpForm textarea::-moz-placeholder {
  color: #fff !important;
}
.rfpForm input {
  color: #fff !important;
}



.relatedCardList {
  display: flex;
  height: 375px;
  /* justify-content: space-between; */
  transition: all 2s;
}
.relatedCardItems {
  padding: 15px 0;
  box-sizing: border-box;
  transition: all 2s;
  float: left;
  min-height: auto;
  display: flex;
  text-decoration: none;
  /* margin-right: 30px;
  margin: 0 15px; */
  position: relative;
}
.relatedCardItems:hover{
  transition: all 2s;
}
.relatedCardList .img {
  background-position: center;
  background-size: cover;
  height: auto;
  margin-top: 35px;
  width: 0px;
  transition: width 2s, min-width 2s, height 4s;
  padding-left: 30px;
  padding-right: 30px;
  margin-right: -60px;
  border-radius: 4px 0 0 4px;
  position: absolute;
}
.img.active {
  background-position: center;
  background-size: cover;
  margin-top: 35px;
  width: 260px;
  /* height: 375px; */
  transition: all 2s;
  padding-left: 30px;
  padding-right: 30px;
  margin-right: -60px;
  border-radius: 4px 0 0 4px;
}
.relatedCardContent {
  padding: 40px;
  background: #f4f4f4;
  box-sizing: border-box;
  height: 100%;
  /* width: 300px; */
  display: flex;
  flex-direction: column;
  transition: all 2s ease;
  z-index: 1;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.active:hover{
  transition: all 2s;
}
.relatedCardContent p {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 1rem 0;
}

.delve-p {
  font-size: 15px;
  color: rgba(36, 35, 35, 0.904);
}
.relatedWhiteBtn {
  font-size: 18px !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.50px;
  text-align: center;
  color: #000000;
  padding: 8px 17px 9px;
  background: #fff;
  border-radius: 0px 15px 15px 0px;
  margin-left: 0px;
  margin-top: 10px;
  display: inline-block;
}
.delve-subheading {
  font-size: 20px !important;
  font-weight: 600;
  color: black;
  text-transform: capitalize;
}
.relatedBtn, 
.common-white-btn, 
.common-white-text-btn, 
.relatedBtn-noborder, 
.relatedBtn-left-arrow, 
.relatedBtn-white-left-arrow, 
.active-btn, 
.download-btn, 
.active-wla-btn, 
.active-left-btn {
  line-height: 1.25em;
}
.relatedBtn {
  font-size: 16px;
  text-transform: uppercase;
  border: 0;
  position: relative;
  text-decoration: none;
  padding-top: 15px;
  padding-right: 30px;
  display: inline-block;
  cursor: pointer;
  background: transparent;
  outline: none;
  text-decoration: none;
}
.relatedBtn:before{
  content: '';
  background-image: linear-gradient(to right,#f37021 10%,#bd1a29 43%,#ed1c24 76%,#f04e30 101%);
  height: 3px;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.relatedBtn:after{
  content: '';
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 8px solid;
  position: absolute;
  bottom: 8px;
  right: 10px;
  transition: all 0.3s ease;
}

.mobile-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
}

.mobile-toggle .fa-angle-down {
  margin-left: 8px; /* Adjust as needed */
}

.mobile-toggle.expanded .fa-angle-down {
  transform: rotate(180deg);
}

.pdf-download-container {
  padding: 20px;
}

.pdf-content {
  background-color: #f0f0f0;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

.capture-button,
.download-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
}

.download-button:hover,
.capture-button:hover {
  background-color: #0056b3;
}


.about-img-wrap-three img:nth-child(1) {
  border-radius: 100px 0 0 0;
}

.about-img-wrap-three img:nth-child(2) {
  border-radius: 0 50px 0 0;
  border: 8px solid white;
  position: absolute;
  right: 0;
  bottom: 25px;
  max-width: 263px;
  z-index: 2;
}
.about-img-wrap-three .experience-wrap {
  width: 506px;
  display: flex;
  align-items: center;
  text-align: left;
  background: #1b5df3;
  border-radius: 0px 0px 0px 60px;
  padding: 40px 195px 50px 45px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.about-img-wrap-three .experience-wrap .title {
  margin-bottom: 0;
  text-align: center;
  font-size: 64px;
  line-height: .8;
  color: #ffffff;
  position: relative;
  padding-right: 13px;
  margin-right: 13px;
}

.wrap-three .experience-wrap .title::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 100%;
  background: #ffffff;
}
.about-img-wrap-three .experience-wrap .title span {
  display: block;
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.1em;
  margin-top: 5px;
}

.about-img-wrap-three .experience-wrap p {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
  color: #ffffff;
  line-height: 1.4;
}
.testimonial-img-three{
  clip-path: polygon(0 0, 85% 0, 100% 15%, 100% 100%, 15% 100%, 0 85%);
  width: 100%;

}

@media (max-width: 1024px) {
  .about-img-wrap-three .experience-wrap {
    display: none !important;
  }
}




.social-share {
  margin: 100px;
  position: relative;
  cursor: pointer;
}
.social-share .social-share__heading {
  font-family: helvetica;
  font-weight: 400;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  position: absolute;
  margin: 0;
  height: 50px;
  width: 270px;
  z-index: 40;
}
.social-share .social-share__heading span svg {
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
}
.social-share ul {
  opacity: 0;
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  position: absolute;
  transition: all 0.3s ease-out;
  display: none;
}
.social-share ul li {
  position: relative;
}
.social-share ul li .share-title {
  color: #a4a4a2;
  background: #000;
  border-radius: 20px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  top: 0px;
  left: -50%;
  min-width: 100px;
  transition: all 0.3s ease-out;
  opacity: 0;
  font-size: 13px;
  text-align: center;
  font-family: helvetica;
  z-index: -1;
}
.social-share ul li:hover .share-title {
  top: -40px;
  opacity: 1;
}
.social-share:hover .social-share__heading {
  visibility: hidden;
  opacity: 0;
}
.social-share:hover ul {
  opacity: 1;
  display: flex;
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #000;
  padding: 1em;
}

.resp-sharing-button {
  transition: 25ms ease-out;
}

.resp-sharing-button__icon svg {
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
  vertical-align: top;

}

.highcharts-credits{
  display: none !important;
}

.chart-container {
  filter: blur(3px); 
}
.copoilt-class ol { 
  list-style-type: decimal; 
  padding-left: 30px;
}
.copoilt-class ul ul, ol ul { 
  list-style-type: circle; 
  list-style-position: inside; 
  margin-left: 20px; 
  margin-top: 10px;
  margin-bottom: 10px;
}
.copoilt-class ol ol, ul ol { 
  list-style-type: lower-latin; 
  list-style-position: inside; 
  margin-left: 15px; 
}

.copoilt-class ol p {
  margin: 0px !important;
}

.copoilt-class {
  font-size: 15px;
}

.copoilt-class strong{
   font-weight: 500;
}
#section-1 {
  scroll-margin-top: 60px; /* Adjust the value as needed */
}
#section-2 {
  scroll-margin-top: 60px; /* Adjust the value as needed */
}
#section-3 {
  scroll-margin-top: 60px; /* Adjust the value as needed */
}
#section-4 {
  scroll-margin-top: 60px; /* Adjust the value as needed */
}
#section-5 {
  scroll-margin-top: 60px; /* Adjust the value as needed */
}
#section-6 {
  scroll-margin-top: 60px; /* Adjust the value as needed */

}
.formatting a, .caseStudyDetails a, .eventDetails a{
  color: #1b5df3 !important;
}
.formatting ul{
  list-style: outside;
  list-style-type: disc;
  white-space: initial;
  padding-inline-start: 48px;
}
.formatting ul li{
  white-space: initial !important;
}
/* #project_overview, #project_goal, #scope_of_work, #submission_request{
  color: #fff !important;
} */


@keyframes jump-1 {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
@keyframes jump-2 {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
}

